<template>
  <v-container fluid :class="{'pa-0': $vuetify.breakpoint.xsOnly}">
    <!-- BREADCRUMBS -->
    <v-row dense v-if="false">
      <v-col cols="12">
        <v-breadcrumbs class="py-0" :class="{'pl-0': $vuetify.breakpoint.smAndUp}" :items="breadcrumbs">
          <v-icon slot="divider" class="xsmall">fas fa-chevron-right</v-icon>
          <template slot="item" slot-scope="props">
            <v-btn
              color="color3"
              class="pa-0 ma-0 xsmall"
              style="min-width: 0"
              text
              :to="props.item.to"
              exact
              small
              :disabled="props.item.disabled"
            >{{props.item.text}}</v-btn>
          </template>
        </v-breadcrumbs>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12">
        <!-- MAIN CONTENT -->
        <v-card>
          <v-toolbar color="color1 color1Text--text" dense>
            <v-toolbar-title>{{tournament.name}} Courts</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
              color="color2 color2Text--text"
              small fab
              @click.stop="settingsDialog=true"
            >
              <v-icon>fas fa-ellipsis-v</v-icon>
            </v-btn>
          </v-toolbar>
          <v-container fluid class="pt-0">
            <v-row dense>
              <v-switch
                label="Used courts only"
                v-model="usedOnly"
                dense
                color="success"
              ></v-switch>
              <v-spacer></v-spacer>
              <v-btn
                color="color3"
                x-small text
                @click.stop="initEvents"
              >update matches</v-btn>
              <court-grouper :tournament="tournament" v-if="view.adminAndRefs"></court-grouper>
              <add-courts :tournament="tournament" v-if="view.adminAndRefs"></add-courts>
            </v-row>
            <v-row dense>
              <v-col cols="12">
                <div class="slide-wrapper" ref="bracketContainer">
                  <v-sheet :width="`${iWidth * courtNames.length}px`"  height="60vh" color="red" class="slide-content printable" ref="bracket">
                    <v-calendar
                      ref="calendar"
                      color="primary"
                      type="category"
                      :categories="courtNames"
                      style="width: 100%;"
                      :events="events"
                      :value="calVal"
                      :interval-minutes="iMinutes"
                      :interval-count="iCount"
                      :first-time="calStartTime"
                      :event-color="getEventColor"
                      :event-ripple="false"
                      category-show-all
                      @click:event="selectMatch"
                      :interval-height="iHeight"
                      category-hide-dynamic
                    >
                      <template v-slot:event="{ event, timeSummary }">
                        <div class="pa-1 v-event-draggable">
                          <template v-if="!showNames">
                            <div>{{event.titleArray.slice(0,2) | pipeArray}}</div>
                            <div>{{event.meta.name}}</div>
                            <div v-if="false">{{event.titleArray.slice(1) | pipeArray}}</div>
                          </template>
                          <div v-else>{{event.titleArray | pipeArray}}</div>
                          <div v-if="!showNames">{{timeSummary()}}</div>
                          <div v-if="showNames" :class="{ 'font-weight-bold': event.meta.winner === 'home'}">{{event.meta.home}}{{event.meta.winner === 'home' ? ' (W)' : ''}}</div>
                          <div v-if="showPlayers" :class="{ 'font-weight-bold': event.meta.winner === 'home'}">{{event.meta.homePlayers}}</div>
                          <div v-if="showNames" :class="{ 'font-weight-bold': event.meta.winner === 'away'}">{{event.meta.away}}{{event.meta.winner === 'away' ? ' (W)' : ''}}</div>
                          <div v-if="showPlayers" :class="{ 'font-weight-bold': event.meta.winner === 'away'}">{{event.meta.awayPlayers}}</div>
                          <div v-if="showNames && event.meta.ref" class="pl-3">Ref: {{event.meta.ref}}</div>
                        </div>
                      </template>
                    </v-calendar>
                  </v-sheet>
                </div>
              </v-col>
            </v-row>
          </v-container>
          <!-- MATCH DIALOG -->
          <v-dialog
            v-if="selectedMatch"
            v-model="matchDialog"
            scrollable
            :persistent="false"
            width="90vw"
            max-width="800px"
            transition="dialog-transition"
          >
            <full-match-card
              :key="`${selectedMatch.number}-${selectedMatch.id}`"
              style="width: 100%"
              :matchIn="selectedMatch"
              :pool="selectedPool"
              :bracket="selectedBracket"
              :bracketCard="!!selectedBracket"
              :division="selectedDivision"
              :round="selectedDay"
              :jump="true"
              @match-saved="onMatchSaved"
              :lineupLock="getLineupLock(selectedDivision, selectedMatch, selectedPool, selectedBracket)"
            ></full-match-card>
          </v-dialog>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="view.adminAndRefs">
      <v-col cols="12" v-if="tournament.freePlayRounds.length > 0">
        <match-up-creator
          v-for="r in tournament.freePlayRounds"
          :key="r.id"
          :time="r.dtStart"
          :roundId="r.id"
          :small="true"
          @saved="initEvents"
        ></match-up-creator>
      </v-col>
      <v-col cols="12">
        <v-btn color="color3" text @click.stop="onDownloadClick">Export Schedule</v-btn>
        <v-btn color="color3" text @click.stop="delayClick" v-if="view.adminAndRefs">Add/Remove Delay</v-btn>
        <v-btn color="color3" text @click.stop="doCourtTimeHardCode" v-if="view.vbl">Hard Code Court and Times</v-btn>
        <v-btn
          fab
          color="color3Text color3--text"
          class="not-printable"
          data-html2canvas-ignore
          bottom
          right
          fixed
          @click.stop="onPrintClick2"
          :loading="printing"
        >
          <v-icon>far fa-print</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="12" v-if="courtNames && user && user.vbl">
        {{courtNames.length}} Courts: {{ courtNames | formatArray }}
      </v-col>
    </v-row>
    <!-- SETTINGS DIALOG -->
    <v-dialog
      v-model="settingsDialog"
      scrollable
      :persistent="events.length === 0"
      max-width="500px"
      transition="dialog-transition"
    >
      <v-card>
        <v-toolbar color="color2 color2Text--text">
          <v-toolbar-title>Court Manager Settings</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            color="color1 color1Text--text"
            small fab
            @click.stop="settingsDialog = false"
          >
            <v-icon>fas fa-times</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="pt-3">
          <v-row dense> <!-- SELECTORS -->
            <!-- DAY SELECTOR -->
            <v-col cols="12">
              <v-select
                dense
                :items="days"
                v-model="day"
                label="Day"
                color="color3"
                item-color="color3"
                :solo-inverted="days.length > 1"
              ></v-select>
            </v-col>
            <v-col cols="12" class="pt-6">
              <v-range-slider
                v-model="timeRange"
                min="0"
                max="24"
                thumb-label="always"
                color="color3"
              >
              <template v-slot:thumb-label="props">
                {{getTime(props)}}
              </template>

              </v-range-slider>

            </v-col>
            <!-- DIVISION SELECTOR -->
            <v-col cols="12">
              <v-select
                dense
                :items="divisions"
                v-model="displayDivisions"
                label="Division"
                color="color3"
                item-color="color3"
                chips small-chips deletable-chips
                multiple
              >
                <template v-slot:prepend-item>
                  <v-list-item
                    ripple
                    @mousedown.prevent
                    @click="toggleDivisions"
                  >
                    <v-list-item-action>
                      <v-icon :color="allDivisions ? 'error' : ''">
                        {{ divisionSelectIcon }}
                      </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        Select All
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                  >
                    <v-list-item-action>
                      <v-btn x-small color="success" @click.stop="toggleGender([1,4])">Womens/Girls</v-btn>
                    </v-list-item-action>
                    <v-list-item-action>
                      <v-btn x-small color="success" @click.stop="toggleGender([3,7])">All Mens/Boys</v-btn>
                    </v-list-item-action>
                    <v-list-item-action>
                      <v-btn x-small color="success" @click.stop="toggleGender([2,5])">All Coed</v-btn>
                    </v-list-item-action>
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
                </template>
              </v-select>
            </v-col>
            <!-- COURT SELECTOR -->
            <v-col cols="12">
              <v-autocomplete
                dense
                :items="courts"
                v-model="selectedCourts"
                label="Courts"
                color="color3"
                item-color="color3"
                multiple
                clearable
                :menu-props="{ closeOnContentClick: true }"
              >
              </v-autocomplete>
            </v-col>
            <!-- GROUP SELECTOR -->
            <v-col cols="12" v-if="!!groups">
              <v-select
                dense
                :items="groups"
                label="Court Groups"
                color="color3"
                item-color="color3"
                item-text="name"
                return-object
                :menu-props="{ closeOnContentClick: true }"
                @input="addGroup"
                clearable
              >
              </v-select>
            </v-col>
            <!-- INTERVAL LENGTH -->
            <v-col cols="6">
              <v-select
                :items="[
                  15,20,25,30,35,40,45,50,55,60
                ]"
                v-model="iMinutes"
                label="Interval Length"
                hint="In Minutes"
                persistent-hint
                :menu-props="{ closeOnContentClick: true }"
                color="color3"
                item-color="color3"
              ></v-select>
            </v-col>
            <!-- INTERVAL SIZE -->
            <v-col cols="6">
              <v-select
                :items="[
                  {text: 'Small', value: 16},
                  {text: 'Medium', value: 32},
                  {text: 'Large', value: 48},
                  {text: 'X-Large', value: 64}
                ]"
                v-model="iHeight"
                label="Interval Height"
                :menu-props="{ closeOnContentClick: true }"
                color="color3"
                item-color="color3"
              ></v-select>
            </v-col>
            <!-- EVENT WIDTH -->
            <v-col cols="12" class="pt-6">
              <v-slider
                v-model="iWidth"
                color="color3"
                thumb-label="always"
                min="50"
                max="500"
                step="10"
                label="Match Width"
              ></v-slider>
            </v-col>
            <v-col cols="6">
              <v-switch label="Show Names" v-model="showNames" color="success"></v-switch>
            </v-col>
            <v-col cols="6">
              <v-switch label="Show Players" v-model="showPlayers" color="success"></v-switch>
            </v-col>
            <v-col cols="6">
              <v-switch label="Color by gender" v-model="color2" color="success"></v-switch>
            </v-col>
            <v-col cols="6" v-if="user && user.vbl">
              <v-text-field
                label="Tournament Id"
                v-model="addId"
                type="number"
                dense
              >
                <template v-slot:append-outer>
                  <v-btn
                    color="success"
                    small
                    :disabled="!addId"
                    @click.stop="addTournament"
                  >
                    add
                  </v-btn>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
                <div class="error--text text-center">
                <template v-if="autoS && !noAuto && this.events.length > 0">
                  Auto Update:
                  <countdown
                    :lengthInSeconds="+autoS"
                    :mini="true"
                    @expired="initEvents"
                    :cycle="true"
                  ></countdown>
                  <v-btn x-small color="black" @click.stop="noAuto = true" text>cancel</v-btn>
                </template>
                <v-btn x-small color="success" @click.stop="noAuto = false" text v-if="noAuto && this.events.length > 0">start</v-btn>
              </div>
            </v-col>
            <v-col cols="6">
              <v-text-field
                label="Auto Update"
                v-model="autoS"
                type="number"
                dense
                hint="Refresh rate in seconds"
                persistent-hint
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="color3"
            text
            @click.stop="initEvents"
          >refresh matches</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="delayDialog"
      scrollable
      max-width="500px"
      :persistent="loading"
      transition="dialog-transition"
    >
      <v-card>
        <v-toolbar color="color2 color2Text--text">
          <v-toolbar-title>Push A Delay</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="pt-3">
          <v-row dense>
            <!-- COURT SELECTOR -->
            <v-col cols="12">
              <v-autocomplete
                dense
                :items="courts"
                v-model="delayCourts"
                label="Courts"
                color="color3"
                item-color="color3"
                multiple
                clearable
                :menu-props="{ closeOnContentClick: true }"
                :disabled="delayConfirm"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="6">
              <v-text-field
                label="Minutes"
                type="number"
                v-model="delayMinutes"
                :disabled="delayConfirm"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <date-time-picker
                :datetime.sync="delayStartTime"
                label="Start"
                type="time"
                :disabled="delayConfirm"
              ></date-time-picker>
            </v-col>
          </v-row>
          <v-expand-transition>
            <v-row dense v-if="delayConfirm">
              <v-col cols="12">
                Shift all matches on courts {{delayCourts | formatArray}} starting at or after {{delayStartTime | time24To12}} by {{delayMinutes}} minutes?
              </v-col>
              <v-col cols="12" class="text-center">
                <v-btn color="success" class="mr-3" @click.stop="doDelay" :loading="loading">Yes</v-btn>
                <v-btn color="error" @click.stop="delayConfirm = false" :disabled="loading">No</v-btn>
              </v-col>
            </v-row>
          </v-expand-transition>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-fab-transition>
            <v-btn
              v-if="!delayConfirm"
              color="color3 color3Text--text"
              small fab
              :disabled="!delayValid"
              @click.stop="delayConfirm = true"
            >
              <v-icon>fas fa-caret-right</v-icon>
            </v-btn>
          </v-fab-transition>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import FullMatchCard from '@/components/Tournament/Match/FullMatchCard'
import moment from 'moment'
import flatten from '@/helpers/ArrayFlatten'
import * as actions from '@/store/ActionTypes'
import * as mutations from '@/store/MutationTypes'
import { firstBy } from 'thenby'
import Papa from 'papaparse'
import Match from '../../../classes/Match'
const CourtGrouper = () => import('@/components/Tournament/Courts/CourtGrouper')
const AddCourts = () => import('@/components/Tournament/Courts/AddCourtsDialog')
const Countdown = () => import('@/components/Utils/Countdown')
const MatchUpCreator = () => import('@/components/Tournament/Courts/MatchUpCreatorDialog')
const DateTimePicker = () => import('@/components/Utils/DateTimePicker.vue')

export default {
  props: ['noBtn', 'open'],
  data () {
    return {
      breadcrumbs: [
        {
          text: 'Tournament Home',
          to: { name: 'tournament-brochure' },
          exact: true,
          disabled: false
        },
        {
          text: 'Courts',
          exact: true,
          disabled: true
        }
      ],
      dialog: false,
      delayDialog: false,
      settingsDialog: true,
      hideCourts: [],
      day: null,
      division: null,
      matchDialog: false,
      selectedMatch: null,
      selectedPool: null,
      selectedBracket: null,
      selectedDivision: null,
      selectedDay: null,
      calStartTime: '08:00',
      endTime: '20:00',
      selectedGroups: [],
      selectedCourts: [],
      iMinutes: 30,
      iHeight: 32,
      hScroll: 0,
      numOfCourts: 6,
      dragEvent: null,
      dragStart: null,
      createEvent: null,
      createStart: null,
      extendOriginal: null,
      addId: null,
      eventMeta: [],
      displayDivisions: [],
      usedOnly: true,
      courts: [],
      color2: false,
      autoS: 10,
      noAuto: false,
      showNames: false,
      showPlayers: false,
      iWidth: 200,
      printStyle: false,
      printing: false,
      delayCourts: [],
      delayMinutes: 60,
      delayStartTime: null,
      delayConfirm: false,
      loading: false
    }
  },
  computed: {
    ...mapGetters(['tournament', 'view', 'color1IsDark', 'user', 'courtManagerSettings', 'userTeams']),
    delayValid () {
      return (this.delayMinutes || 0) !== 0 && this.delayCourts.length > 0 && !!this.delayStartTime
    },
    timeValues () {
      return [...Array(24).keys()].map(i => `${String(i).padStart(2, 0)}:00`)
    },
    timeRange: {
      get: function () {
        const a = this.calStartTime.split(':')
        const b = this.endTime.split(':')
        return [+a[0], +b[0]]
      },
      set (v) {
        this.calStartTime = `${String(v[0]).padStart(2, 0)}:00`
        this.endTime = `${String(v[1]).padStart(2, 0)}:00`
      }
    },
    iCount () {
      return (moment(this.endTime, 'HH:mm').diff(moment(this.calStartTime, 'HH:mm'), 'hours') * 60) / this.iMinutes
    },
    matches () {
      return this.tournament.matches
    },
    filteredCourts () {
      return this.selectedCourts.length > 0 ? this.selectedCourts : this.courts
    },
    courtNames () {
      return this.filteredCourts // .map(m => m.toLowerCase().includes('court') ? m : `Court ${m}`)
    },
    events () {
      return this.eventMeta.filter(f => f.dayWithMo === this.day && this.displayDivisions.includes(f.meta.division.id))
    },
    groups () {
      return this.tournament.jProps && this.tournament.jProps.courtGroups
    },
    days () {
      return [...new Set(this.matches.filter(f => f.dayWithMo).map(m => m.dayWithMo))]
    },
    calVal () {
      return this.day ? moment(this.day, 'MMM Do YYYY').format('YYYY-MM-DD') : null
    },
    divisions () {
      return this.tournament.publicDivisions.filter(f => f.hydrated).map(d => {
        return {
          text: d.name,
          value: d.id,
          genderId: d.gender.id
        }
      })
    },
    allDivisions () {
      return this.displayDivisions.length === this.divisions.length
    },
    someDivisions () {
      return this.displayDivisions.length > 0 && !this.allDivisions
    },
    divisionSelectIcon () {
      if (this.allDivisions) return 'fas fa-times-square'
      if (this.someDivisions) return 'fas fa-minus-square'
      return 'far fa-square'
    }
  },
  methods: {
    getTime (p) {
      const m = moment(`2000-01-01T${String(p.value).padStart(2, 0)}:00`)
      return m.format('hA')
    },
    toggleDivisions () {
      this.$nextTick(() => {
        if (this.allDivisions) {
          this.displayDivisions = []
        } else {
          this.displayDivisions = this.divisions.map(m => m.value)
        }
      })
    },
    toggleGender (genderIds) {
      const x = [...this.displayDivisions, ...this.divisions.filter(f => genderIds.includes(f.genderId)).map(m => m.value)]
      this.displayDivisions = [...new Set(x)]
    },
    startDrag ({ event, timed, eventParsed }) {
      if (event && timed) {
        console.log(event)
        // console.log(eventParsed)
        event.start = new Date(event.start).getTime()
        event.end = new Date(event.end).getTime()
        this.dragEvent = event
        this.dragTime = null
      }
    },
    startTime (tms) {
      if (this.dragEvent && this.dragTime === null) {
        // console.log(tms)
        const mouse = this.toTime(tms)
        console.log(mouse)
        const start = this.dragEvent.start

        this.dragTime = mouse - start
      }
    },
    mouseMove (tms) {
      if (this.dragEvent && this.dragTime !== null) {
        const mouse = this.toTime(tms)
        const start = this.dragEvent.start
        const end = this.dragEvent.end
        const duration = end - start
        const newStartTime = mouse - this.dragTime
        const newStart = this.roundTime(newStartTime)
        const newEnd = newStart + duration

        this.dragEvent.start = newStart
        this.dragEvent.end = newEnd
        console.log(this.dragEvent)
      }
    },
    endDrag () {
      this.dragTime = null
      this.dragEvent = null
      this.createEvent = null
      this.createStart = null
      this.extendOriginal = null
    },
    cancelDrag () {
      if (this.createEvent) {
        if (this.extendOriginal) {
          this.createEvent.end = this.extendOriginal
        } else {
          const i = this.events.indexOf(this.createEvent)
          if (i !== -1) {
            this.events.splice(i, 1)
          }
        }
      }

      this.createEvent = null
      this.createStart = null
      this.dragTime = null
      this.dragEvent = null
    },
    roundTime (time, down = true) {
      const roundTo = 15 // minutes
      const roundDownTime = roundTo * 60 * 1000

      return down
        ? time - time % roundDownTime
        : time + (roundDownTime - (time % roundDownTime))
    },
    toTime (tms) {
      return new Date(tms.year, tms.month - 1, tms.day, tms.hour, tms.minute).getTime()
    },
    getEventColor (event) {
      const c = this.color2 ? event.color2 : event.color
      return event.meta.parent.type === 'pool' ? c : `${c} darken-2`
    },
    selectDay () {
      if (this.$route.query.day) {
        this.checkSetDay(this.$route.query.day)
      } else if (this.courtManagerSettings.day) {
        const d = moment(this.courtManagerSettings.day, 'MMM Do YYYY')
        this.checkSetDay(d)
      }
      if (!this.day && this.days.length) {
        this.day = this.days[0]
      }
    },
    checkSetDay (day) {
      const d = moment(day)
      if (d.isValid) {
        const x = this.days.find(f => f === d.format('MMM Do YYYY'))
        if (x) {
          this.day = x
          // this.$router.replace({ query: null })
          return true
        }
      }
      return false
    },
    selectMatch ({ event }) {
      this.selectedDivision = this.tournament.divisions.find(f => f.id === event.meta.division.id)
      this.selectedDay = this.selectedDivision.days.find(f => f.id === event.meta.round.id)
      this.selectedPool = null
      this.selectedBracket = null
      const m = event
      this.selectedMatch = event.meta.id > 0 ? this.tournament.matches.find(f => f.id === event.meta.id) : null
      if (m.meta.parent.type === 'pool') {
        this.selectedPool = this.tournament.pools.find(f => f.id === m.meta.parent.id)
        if (this.selectedMatch === null) this.selectedMatch = this.selectedPool.matches.find(f => f.number === event.meta.number)
      } else {
        this.selectedBracket = this.tournament.brackets.find(f => f.id === m.meta.parent.id)
        if (this.selectedMatch === null) this.selectedMatch = this.selectedBracket.matches.find(f => f.number === event.meta.number)
      }
      this.matchDialog = true
    },
    onMatchSaved () {
      this.matchDialog = false
      this.selectedMatch = null
    },
    applyGroupFilter () {
      if (this.selectedGroups.length === 0) {
        this.hideCourts = []
        return
      }

      const show = flatten(this.selectedGroups.map(m => m.courts))
      this.hideCourts = this.allCourts.filter(f => !show.includes(f))
    },
    addGroup (g) {
      this.selectedCourts.push(...g.courts)
    },
    addTournament () {
      this.$store.commit(mutations.SET_TOURNAMENT_ALLOWADD, true)
      this.$store.dispatch(actions.SUBSCRIBE_TO_TOURNAMENT, { tournamentId: this.addId, add: true })
      this.addId = null
    },
    initEvents () {
      this.loading = true
      const data = this.tournament.getMatchMeta()
      this.mapEvents(data)
      this.mapCourts()
      this.loading = false
    },
    mapEvents (data) {
      const a = data.filter(f => f.startTime && f.court)
      this.eventMeta = a.map(m => {
        const c = m.court
        return {
          start: m.startTime.format('YYYY-MM-DD HH:mm'),
          end: m.endTime.format('YYYY-MM-DD HH:mm'),
          category: c, // .toLowerCase().includes('court') ? c : `Court ${c}`,
          name: m.titleArray ? m.titleArray.join(' | ') : m.name,
          titleArray: m.titleArray,
          color: m.status === 'Complete' ? 'success' : m.status === 'Started' ? 'warning' : 'error',
          color2: m.division.gender ? 'blue' : 'pink',
          court: c,
          dayWithMo: m.dayWithMo,
          meta: m
        }
      })
    },
    mapCourts () {
      let base = this.tournament.courts
      if (this.usedOnly) {
        const used = this.events.map(m => m.court)
        base = base.filter(f => used.includes(f))
      }
      const num = base.filter(f => !/\D/.test(f)).sort((a, b) => a - b)
      const nonNum = base.filter(f => /\D/.test(f)).sort(firstBy((a, b) => b - a).thenBy((a, b) => (+a.replace(/\D/g, '')) - (+b.replace(/\D/g, ''))))
      this.courts = [...num, ...nonNum]
    },
    onAutoUpdate () {
      this.loading = true
      this.$nextTick(() => {
        this.initEvents()
      })
    },
    getSettings () {
      this.selectDay(this.courtManagerSettings.day)
      // divisions: [],
      // this.selectedCourts = this.courtManagerSettings.courts
      this.iMinutes = this.courtManagerSettings.iMinutes
      this.iHeight = this.courtManagerSettings.iHeight
      this.iWidth = this.courtManagerSettings.iWidth
      this.showNames = this.courtManagerSettings.showNames
      this.showPlayers = this.courtManagerSettings.showPlayers
      this.color2 = this.courtManagerSettings.color2
      this.autoS = this.courtManagerSettings.autoS
      this.calStartTime = this.courtManagerSettings.calStartTime || '08:00'
      this.endTime = this.courtManagerSettings.endTime || '20:00'
    },
    pushSettings () {
      this.$store.commit(mutations.SET_COURT_MANAGER_SETTINGS, {
        day: this.day,
        // courts: this.selectedCourts,
        iMinutes: this.iMinutes,
        iHeight: this.iHeight,
        iWidth: this.iWidth,
        showNames: this.showNames,
        showPlayers: this.showPlayers,
        color2: this.color2,
        autoS: this.autoS,
        calStartTime: this.calStartTime,
        endTime: this.endTime
      })
    },
    onDownloadClick () {
      var fileName = `${this.tournament.name.replace(/ /g, '_')}_Full_Schedule.csv`
      var data = this.exportData()
      this.createFileForDownload(data, fileName)
    },
    createFileForDownload (data, name) {
      var opt = { header: !this.avpaFormat }
      var csv = Papa.unparse(data, opt)
      var blob = new Blob([csv])
      var a = window.document.createElement('a')
      a.href = window.URL.createObjectURL(blob, { type: 'text/plain' })
      a.download = name
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    },
    exportData () {
      return this.tournament.fullTimeline.filter(f => !!(f.court && f.unix))
        .map(m => {
          const homePlayers = m.m.getLineupPlayers(true, this.tournament.activeTeams, true, 2)
          const h = m.homeTeam ? `${m.homeTeam.name}` : 'TBD'
          const awayPlayers = m.m.getLineupPlayers(false, this.tournament.activeTeams, true, 2)
          const a = m.awayTeam ? `${m.awayTeam.name}` : 'TBD'
          return {
            Home: h,
            HomePlayers: homePlayers ? homePlayers.name : null,
            HomeSeed: m.homeTeam ? m.homeTeam.seed : null,
            Away: a,
            AwayPlayers: awayPlayers ? awayPlayers.name : null,
            AwaySeed: m.awayTeam ? m.awayTeam.seed : null,
            Date: moment.unix(m.unix).format('L'),
            Time: m.time,
            Court: m.court,
            Division: m.division
          }
        })
    },
    togglePrintStyle () {
      this.printStyle = !this.printStyle
      const el = this.$refs.bracketContainer
      // const bracketEl = this.$refs.bracket
      if (this.printStyle) {
        el.classList.remove('container')
        el.classList.remove('container--fluid')
        el.style.width = `${this.iWidth * this.courtNames.length}px`
        el.style.height = 'auto'
      } else {
        el.classList.add('container')
        el.classList.add('container--fluid')
        el.style.removeProperty('width')
        el.style.height = '60vh'
      }
    },
    async onPrintClick2 () {
      this.printing = true
      const el = this.$refs.bracketContainer
      const bracketEl = this.$refs.bracket.$el
      el.classList.remove('container')
      el.classList.remove('container--fluid')
      bracketEl.style.height = 'auto'

      const options = {
        type: 'dataURL',
        backgroundColor: '#fafafa'
      }
      const output = await this.$html2canvas(bracketEl, options)
      el.classList.add('container')
      el.classList.add('container--fluid')
      bracketEl.style.height = '60vh'

      const img = new Image()
      img.src = output
      img.style = 'width: 100%'
      const w = window.open('SchedulePrinter')
      img.onload = function () {
        w.print()
        // w.close()
      }
      console.log('eher')
      w.document.write(`<div id="printMe" style="width: 11in; height: 8.5in; background: url(${output}); background-repeat:none;background-repeat: no-repeat;background-size: contain;"></div>`)
      w.document.title = 'Schedule Printing'
      // const temp = document.createElement('div')
      // temp.innerHTML = '<style>@media print { @page { size: landscape !important; } }</style>'
      // const head = w.document.head
      // while (temp.firstChild) {
      // head.appendChild(temp.firstChild)
      // }
      const b = w.document.getElementsByTagName('BODY')[0]
      b.style = 'margin:0;; background: #eeeeee;'
      this.printing = false
    },
    delayClick () {
      this.initDelay()
      this.delayDialog = true
    },
    doDelay () {
      const start = moment.utc(`${this.day} ${this.delayStartTime}`, 'MMM Do YYYY HH:mm')
      const matches = this.events.map(m => this.getMatch(m)).filter(f => this.delayCourts.includes(f.court) && start.isSameOrBefore(f.startTime))
      if (matches && matches.length) {
        matches.forEach(m => {
        })
        const dto = matches.map(m => {
          const x = new Match(null, m.fullDto, m.type)
          x.startTime = x.startTime.add(this.delayMinutes, 'minutes')
          return x.dto
        })
        this.loading = true
        this.$VBL.post.matches(dto, false, false, false)
          .then(r => {
            this.$nextTick(() => {
              this.initEvents()
              this.delayDialog = false
            })
          })
          .catch(err => console.log(err.response))
      }
    },
    doCourtTimeHardCode () {
      const matches = this.events.map(m => this.getMatch(m))
      if (matches && matches.length) {
        const dto = matches.map(m => {
          const x = {
            id: m.id,
            poolId: m.poolId,
            bracketId: m.bracketId,
            number: m.number,
            court: m.court || null,
            startTime: m._startTime
          }
          return x
        })

        // console.log(dto)
        this.loading = true
        this.$VBL.post.matches(dto, false, false, false)
          .then(r => {
            this.$nextTick(() => {
              this.initEvents()
            })
          })
          .catch(err => console.log(err.response))
      }
    },
    getMatch (event) {
      const m = event
      let match = event.meta.id > 0 ? this.tournament.matches.find(f => f.id === event.meta.id) : null
      if (m.meta.parent.type === 'pool') {
        const pool = this.tournament.pools.find(f => f.id === m.meta.parent.id)
        if (match === null) match = pool.matches.find(f => f.number === event.meta.number)
      } else {
        const bracket = this.tournament.brackets.find(f => f.id === m.meta.parent.id)
        if (match === null) match = bracket.matches.find(f => f.number === event.meta.number)
      }
      return match
    },
    initDelay () {
      this.delayMinutes = 60
      this.delayCourts = [...this.courts]
      this.delayConfirm = false
    },
    getLineupLock (d, m, p, b) {
      var start = m.startTime
      if (m.isDual) {
        const dual = p ? p.duals.find(f => f.n === m.dualN) : b.duals.find(f => f.n === m.dualN)
        if (dual) {
          start = dual.startTime
        }
      }

      return d && d.lineupLockObj(start, this.view, this.userTeams)
    }
  },
  components: {
    FullMatchCard,
    CourtGrouper,
    AddCourts,
    Countdown,
    MatchUpCreator,
    DateTimePicker
  },
  watch: {
    days: 'selectDay',
    usedOnly: 'mapCourts',
    displayDivisions: 'mapCourts',
    divisions: 'initEvents',
    'tournament.fullyHydrated': function (v) {
      if (v && this.displayDivisions.length === 0) {
        this.toggleDivisions()
      }
    },
    showNames: function (v) {
      if (v) {
        // this.iMinutes = 15
        this.iHeight = 48
      }
    },
    settingsDialog: function (v) {
      if (!v) {
        this.pushSettings()
      }
    }
  },
  mounted () {
    this.getSettings()
    this.toggleDivisions()
    this.initEvents()
  },
  created () {
    // this.tournament.updateMatchMeta()
  }
}
</script>

<style scoped>
>>> .v-calendar-daily_head-day-label, >>> .v-calendar-daily_head-weekday {
  display: none;
}
.slide-wrapper {
    contain: content;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    overflow: auto;
}
.slide-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    position: relative;
    -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
    white-space: nowrap;
}
</style>
